<template>
  <NavBar/>
  <div class="container-fluid viewport">
    <div class="row h-100">
      <div v-if="authStore.user" class="row h-100 overflow-auto col-auto">
        <SideBar/>
      </div>
      <div class="h-100 overflow-auto col px-0">
        <router-view/>
      </div>
    </div>
  </div>
  <Toasts/>
</template>

<script setup>
import {onMounted, provide, ref} from "vue";
import NavBar from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";
import {useAuthStore} from "@/stores";
import Toasts from "@/components/Toasts.vue";


const envs = import.meta.env
provide('envs', envs)
const authStore = ref(useAuthStore());

const currentVersion = ref(localStorage.getItem("app_version") || null);

// Function to check for updates
const checkForUpdates = async () => {
  try {
    const response = await fetch("/version.json", {cache: "no-store"});
    const {version} = await response.json();

    if (!currentVersion.value) {
      currentVersion.value = version;
      localStorage.setItem("app_version", version);
    } else if (currentVersion.value !== version) {
      alert("A new version of the site is available. Reloading...");
      localStorage.setItem("app_version", version);
      window.location.reload();
    }
  } catch (error) {
    console.error("Error checking for updates:", error);
  }
};

// Run the check periodically
onMounted(() => {
  checkForUpdates();
  setInterval(checkForUpdates, 60000); // Check every 60 seconds
});
</script>

<style scoped>
.viewport {
  height: calc(100vh - 3rem);
}
</style>