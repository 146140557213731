import {defineStore} from "pinia";
import {AuthService} from "@/services";
import router from "@/router";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        access: JSON.parse(localStorage.getItem('access')),
        returnUrl: null,
    }),
    actions: {
        async login(data) {
            const response = await AuthService.login(data);

            if (response.status === 200 && response.data.access !== undefined) {
                this.user = response.data.user;
                this.access = response.data.access;
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('access', JSON.stringify(response.data.access));
                return this.access;
            }
        },
        async logout() {
            this.user = null;
            localStorage.removeItem('user');
            localStorage.removeItem('access');
            await router.push({ name: "Login" });
        },
        async register(data) {
            const response = await AuthService.register(data);
            // update pinia state
            this.user = response;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(response));
        },
        async verify() {
            const data = { token: this.access };
            await AuthService.verify(data);
        },
        isSuperuser() {
            return this.user?.is_superuser ?? false;
        },
        // checkPermission(permission) {
        //     let all_permissions = [...this.user?.user_permissions ?? [], ...this.user?.group_permissions ?? []];
        //     return this.user?.all_permissions?.find(e => e.name === permission) ?? false;
        // }
    }
});